import React, { useMemo } from 'react'

import { AGENT_LINKS, NAVBAR_LINKS } from '@/constants'
import clsx from 'clsx'
import { UniversalNavbarExpanded } from 'ethos-design-system'
import { cmsModuleAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { transmitRiskToken } from 'lib/TransmitSecurity/riskToken'

import { usePaidLandersUpdate } from '@/hooks/features/usePaidLandersUpdate'

import { AstaNavbar as ExperimentNavbar } from '../ExperimentalComponents/Asta/AstaNavbar'
import { NavbarWithPartner as ExperimentNavbarWithPartner } from '../ExperimentalComponents/Asta/NavbarWithPartner'
import { LogoOnlyNavbar } from './LogoOnlyNavbar/LogoOnlyNavbar'
import styles from './Navbar.module.scss'
import { PhoneNavbar } from './PhoneNavbar'

type CTAObject = {
  href: string
  title: string
}

export enum NavbarAdminOptions {
  Disabled = 'disabled',
  Full = 'full',
  Phone = 'phone',
}

interface NavbarProps {
  navbarCtaOverride: boolean | { ctaLabel?: string; clickthroughUrl?: string }
  animatedNavbar: NavbarAdminOptions
  agentLayout: boolean
  noNavbar: boolean
  navbarLogoOnly: boolean
  hideDesktopCta: boolean
  partnerLogo: React.ReactNode
  partnerLogoMobile: React.ReactNode
  partnerLogoSeparator: 'plus' | 'pipe'
  settings: Record<string, any>
  isAstaLayout?: boolean
  isSofi?: boolean
}

const getCTAObject = ({
  navbarCtaOverride,
}: Pick<NavbarProps, 'navbarCtaOverride'>): CTAObject => {
  if (!navbarCtaOverride || typeof navbarCtaOverride !== 'object')
    return NAVBAR_LINKS.CTA
  return {
    ...NAVBAR_LINKS.CTA,
    href: navbarCtaOverride?.clickthroughUrl || NAVBAR_LINKS.CTA.href,
    title: navbarCtaOverride?.ctaLabel || NAVBAR_LINKS.CTA.title,
  }
}

export const Navbar = (props: NavbarProps) => {
  const { isTreatment: isPaidLandersTreatment } = usePaidLandersUpdate()

  const navbarClassnames = {
    [styles.navbar]: true,
    // Use these styles for loading experiments in the navbar
    // [styles.loading]: isLoading,
    // [styles.show]: !isLoading,
  }

  const {
    navbarCtaOverride,
    agentLayout,
    noNavbar,
    navbarLogoOnly,
    hideDesktopCta,
    partnerLogo,
    partnerLogoMobile,
    partnerLogoSeparator = 'plus',
    settings,
    isAstaLayout = false,
    isSofi = false,
  } = props

  const animatedNavbar = props.animatedNavbar !== NavbarAdminOptions.Disabled
  const phoneNavbar = props.animatedNavbar === NavbarAdminOptions.Phone
  const ctaObject = getCTAObject({ navbarCtaOverride })
  const consumerLinks = {
    ...NAVBAR_LINKS,
    CTA: {
      ...ctaObject,
      href: ctaObject.href,
    },
  }

  const initialLinks = agentLayout ? AGENT_LINKS : consumerLinks

  const defaultNavbar = useMemo(
    () => (
      <UniversalNavbarExpanded
        ctaButtonStyle={'Black'}
        hideMobileCta={Boolean(partnerLogoMobile)}
        hideDesktopCta={hideDesktopCta}
        hideSearchIcon={agentLayout}
        hideAccountIcon={agentLayout}
        showSecondaryCta={agentLayout}
        //@ts-ignore
        links={initialLinks}
        estimateExperiment={!agentLayout}
        logoHref={initialLinks.INDEX.href}
        animateDesktopNavbar={animatedNavbar}
        trackCtaClick={() => {
          transmitRiskToken()
          cmsModuleAnalytics.navbarCtaClicked({
            ctaLabel: initialLinks.CTA.title,
            clickthroughUrl: initialLinks.CTA.href,
          })
        }}
        trackSecondaryCtaClick={cmsModuleAnalytics.navbarSecondaryCtaClicked}
        trackItemClick={cmsModuleAnalytics.navbarItemClicked}
        // LinkComponent={Link} //TODO: LINK DOES NOT WORK IN NEXTJS
        partnerLogo={partnerLogo}
        partnerLogoMobile={partnerLogoMobile}
      />
    ),
    [
      hideDesktopCta,
      agentLayout,
      initialLinks,
      animatedNavbar,
      partnerLogo,
      partnerLogoMobile,
    ]
  )

  const navbarComponent = useMemo(() => {
    if (noNavbar) return <></>

    if (navbarLogoOnly) return <LogoOnlyNavbar />

    if (isAstaLayout && !isPaidLandersTreatment) {
      if (partnerLogo && partnerLogoMobile) {
        return (
          <ExperimentNavbarWithPartner
            partnerLogo={partnerLogo}
            partnerLogoMobile={partnerLogoMobile}
            partnerLogoSeparator={partnerLogoSeparator}
            isSofi={isSofi}
          />
        )
      }
      return <ExperimentNavbar />
    }
    if (animatedNavbar) {
      if (phoneNavbar) {
        return <PhoneNavbar settings={settings} />
      }
      return (
        <div className={'AnimatedNavbar animatedBackground'}>
          {defaultNavbar}
        </div>
      )
    }

    return defaultNavbar
  }, [
    noNavbar,
    animatedNavbar,
    defaultNavbar,
    phoneNavbar,
    settings,
    partnerLogo,
    partnerLogoMobile,
    isAstaLayout,
    partnerLogoSeparator,
    isPaidLandersTreatment,
    isSofi,
    navbarLogoOnly,
  ])

  return noNavbar ? null : phoneNavbar ? (
    <>{navbarComponent}</>
  ) : (
    <>
      <div className={styles.placeholder} />
      <div className={clsx(navbarClassnames)}>{navbarComponent}</div>
    </>
  )
}
